<template>
  <div class="page5">
    <Row :gutter="20" class="content">
      <Col span="6">
        <div class="left">
          <!-- <span class="title"><span class="title-text">气瓶</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <Row type="flex" justify="center" align="middle">
            <Col span="20">
              <RadioGroup v-model="buttonSize" type="button" ghost>
                <Radio label="车用气瓶">车用气瓶</Radio>
                <Radio label="液化石油气瓶">液化石油气瓶</Radio>
                <Radio label="工业气瓶">工业气瓶</Radio>
              </RadioGroup>
            </Col>
          </Row> -->
          <div class="left1" style="height: 30%">
            <span class="title"><span class="title-text">健康状态分布</span></span>
            <three-bar-chart ref="chart11" id="bottom_3"></three-bar-chart>
            <!--            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="padding-top: 1rem;">
                          <Col>
                            <Icon type="logo-playstation" size="50" color="#3de7c9"/>
                          </Col>
                          <Col>
                            <Icon type="logo-python" size="50" color="#3de7c9"/>
                          </Col>
                          <Col>
                            <Icon type="logo-python" size="50" color="#3de7c9"/>
                          </Col>
                        </Row>
                        <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
                          <Col>
                            <CountTo
                                style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                                :startVal='startVal'
                                :endVal='endVal'
                                :duration='duration'
                            />
                          </Col>
                          <Col>
                            <CountTo
                                style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                                :startVal='startVal'
                                :endVal='endVal'
                                :duration='duration'
                            />
                          </Col>
                          <Col>
                            <CountTo
                                style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                                :startVal='startVal'
                                :endVal='endVal'
                                :duration='duration'
                            />
                          </Col>
                        </Row>
                        <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 16px;">车辆数</div>
                          </Col>
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 16px;">加气站</div>
                          </Col>
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 16px;">检验机构</div>
                          </Col>
                        </Row>-->
          </div>
          <div class="left1" style="height: 30%">
            <span class="title"><span class="title-text">服务数量</span></span>
            <Row :gutter="80" type="flex" justify="space-around" align="middle">
              <Col>
                <!-- <Icon type="logo-playstation" /> -->
                <Icon type="ios-contact" size="50" color="#3de7c9" />
              </Col>
              <Col>
                <Icon type="ios-contact-outline" size="50" color="#3de7c9" />
                <!-- <Icon type="logo-python"  /> -->
              </Col>
              <Col> <Icon type="md-people" size="50" color="#3de7c9" /> </Col>
            </Row>
            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
              <Col>
                <CountTo style="color: #3de7c9; font-size: 18px; font-weight: bold" :startVal="startVal" :endVal="clendVal" :duration="duration" />
              </Col>
              <Col>
                <CountTo style="color: #3de7c9; font-size: 18px; font-weight: bold" :startVal="startVal" :endVal="jqendVal" :duration="duration" />
              </Col>
              <Col>
                <CountTo style="color: #3de7c9; font-size: 18px; font-weight: bold" :startVal="startVal" :endVal="jyendVal" :duration="duration" />
              </Col>
            </Row>
            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
              <Col>
                <div style="color: #f5f5f5; font-weight: bold; font-size: 14px">已服务</div>
              </Col>
              <Col>
                <div style="color: #f5f5f5; font-weight: bold; font-size: 14px">未服务</div>
              </Col>
              <Col>
                <div style="color: #f5f5f5; font-weight: bold; font-size: 14px">总数</div>
              </Col>
            </Row>
          </div>
          <div class="left1" style="height: 30%">
            <span class="title"><span class="title-text">男女数量</span></span>
            <web-bar ref="chart2" :data="dataBar"></web-bar>
          </div>
        </div>
      </Col>
      <Col span="12">
        <Row class="center-top">
          <!-- <Col span="6">
            <div class="border-box">
              <Row type="flex" align="middle" justify="space-between" class="border-bottom-right">
                <Col span="12">
                  <div style="color: #f5f5f5; font-weight: bold">气瓶数</div>
                </Col>
                <Col span="12">
                  <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="qpendVal" :duration="duration" />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="space-between" class="border-bottom-right">
                <Col span="12">
                  <div style="color: #f5f5f5; font-weight: bold">冲装数</div>
                </Col>
                <Col span="12">
                  <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="czendVal" :duration="duration" />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="space-between" class="border-bottom-right">
                <Col span="12">
                  <div style="color: #f5f5f5; font-weight: bold">检验数</div>
                </Col>
                <Col span="12">
                  <CountTo style="color: #3de7c9; font-size: 25px; font-weight: bold" :startVal="startVal" :endVal="jysendVal" :duration="duration" />
                </Col>
              </Row>
            </div>
          </Col> -->
          <Col span="24">
            <Echart :cdata="cdata1" @chartData="mapData" @quanguoData="qgData" @shengData="sData" />
          </Col>
          <!-- <Col span="6">
            <div class="border-box-right">
              <Row type="flex" align="middle" justify="space-between" class="border-bottom-left">
                <Col span="12">
                  <div style="color: #f5f5f5; font-weight: bold; margin-left: 1rem">电梯数</div>
                </Col>
                <Col span="12">
                  <CountTo
                    style="color: #3de7c9; font-size: 25px; font-weight: bold; float: right"
                    :startVal="startVal"
                    :endVal="endVal"
                    :duration="duration"
                  />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="space-between" class="border-bottom-left">
                <Col span="12">
                  <div style="color: #f5f5f5; font-weight: bold; margin-left: 1rem">维保数</div>
                </Col>
                <Col span="12">
                  <CountTo
                    style="color: #3de7c9; font-size: 25px; font-weight: bold; float: right"
                    :startVal="startVal"
                    :endVal="endVal"
                    :duration="duration"
                  />
                </Col>
              </Row>
              <Row type="flex" align="middle" justify="space-between" class="border-bottom-left">
                <Col span="12">
                  <div style="color: #f5f5f5; font-weight: bold; margin-left: 1rem">检验数</div>
                </Col>
                <Col span="12">
                  <CountTo
                    style="color: #3de7c9; font-size: 25px; font-weight: bold; float: right"
                    :startVal="startVal"
                    :endVal="endVal"
                    :duration="duration"
                  />
                </Col>
              </Row>
            </div>
          </Col> -->
        </Row>
        <!-- <Row :gutter="20" class="center-bottom">
          <Col span="12">
            <div class="right">
              <span class="title"><span class="title-text">每月气瓶待检数</span></span>
              <span class="angle1"></span>
              <span class="angle2"></span>
              <span class="angle3"></span>
              <span class="angle4"></span>
              <dv-scroll-board :config="boardConfig" style="width: 100%; height: 90%; padding: 0.6rem" />
            </div>
          </Col>
          <Col span="12">
            <div class="right">
              <span class="title"><span class="title-text">电梯故障原因</span></span>
              <span class="angle1"></span>
              <span class="angle2"></span>
              <span class="angle3"></span>
              <span class="angle4"></span>
              <dv-scroll-board :config="boardConfig2" style="width: 100%; height: 90%; padding: 0.6rem" />
            </div>
          </Col>
        </Row> -->
      </Col>
      <Col span="6">
        <div class="right">
          <span class="title"><span class="title-text">设备故障率</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <div class="left1" style="height: 40%">
            <pieChart ref="chart11" id="bottom_3"></pieChart>
            <!--            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="padding-top: 1rem;">
                          <Col>
                            <Icon type="logo-playstation" size="50" color="#3de7c9"/>
                          </Col>
                          <Col>
                            <Icon type="logo-python" size="50" color="#3de7c9"/>
                          </Col>
                          <Col>
                            <Icon type="logo-python" size="50" color="#3de7c9"/>
                          </Col>
                        </Row>
                        <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
                          <Col>
                            <CountTo
                                style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                                :startVal='startVal'
                                :endVal='endVal'
                                :duration='duration'
                            />
                          </Col>
                          <Col>
                            <CountTo
                                style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                                :startVal='startVal'
                                :endVal='endVal'
                                :duration='duration'
                            />
                          </Col>
                          <Col>
                            <CountTo
                                style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                                :startVal='startVal'
                                :endVal='endVal'
                                :duration='duration'
                            />
                          </Col>
                        </Row>
                        <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 16px;">车辆数</div>
                          </Col>
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 16px;">加气站</div>
                          </Col>
                          <Col>
                            <div style="color: #f5f5f5;font-weight: bold;font-size: 16px;">检验机构</div>
                          </Col>
                        </Row>-->
          </div>
          <!-- <div class="left1" style="height: 25%">
            <span class="title"><span class="title-text">故障救援时长</span></span>
            <LineMarker ref="chart2" :data="dataBar3"></LineMarker> -->
          <!--            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="padding-top: 0.5rem;">
              <Col>
                <Icon type="logo-playstation" size="50" color="#3de7c9"/>
              </Col>
              <Col>
                <Icon type="logo-python" size="50" color="#3de7c9"/>
              </Col>
              <Col>
                <Icon type="logo-python" size="50" color="#3de7c9"/>
              </Col>
            </Row>
            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
              <Col>
                <CountTo
                    style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                    :startVal='startVal'
                    :endVal='endVal'
                    :duration='duration'
                />
              </Col>
              <Col>
                <CountTo
                    style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                    :startVal='startVal'
                    :endVal='endVal'
                    :duration='duration'
                />
              </Col>
              <Col>
                <CountTo
                    style="color: #3de7c9; font-size: 18px;font-weight: bold;"
                    :startVal='startVal'
                    :endVal='endVal'
                    :duration='duration'
                />
              </Col>
            </Row>
            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
              <Col>
                <div style="color: #f5f5f5;font-weight: bold;font-size: 14px;">车辆数</div>
              </Col>
              <Col>
                <div style="color: #f5f5f5;font-weight: bold;font-size: 14px;">加气站</div>
              </Col>
              <Col>
                <div style="color: #f5f5f5;font-weight: bold;font-size: 14px;">检验机构</div>
              </Col>
            </Row>-->
          <!-- </div> -->
          <div class="left1" style="height: 50%">
            <span class="title"><span class="title-text">工单排行榜</span></span>
            <dv-scroll-ranking-board :config="workOrderRanking" style="width: 100%; height: 100%; padding: 1rem" />
            <!-- <webBars ref="chart2" :data="dataBar2"></webBars> -->
            <!-- <Row :gutter="80" type="flex" justify="space-around" align="middle" style="padding-top: 1rem">
              <Col>
                <Icon type="logo-playstation" size="50" color="#3de7c9" />
              </Col>
              <Col>
                <Icon type="logo-python" size="50" color="#3de7c9" />
              </Col>
              <Col>
                <Icon type="logo-python" size="50" color="#3de7c9" />
              </Col>
            </Row>
            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
              <Col>
                <CountTo style="color: #3de7c9; font-size: 18px; font-weight: bold" :startVal="startVal" :endVal="endVal" :duration="duration" />
              </Col>
              <Col>
                <CountTo style="color: #3de7c9; font-size: 18px; font-weight: bold" :startVal="startVal" :endVal="endVal" :duration="duration" />
              </Col>
              <Col>
                <CountTo style="color: #3de7c9; font-size: 18px; font-weight: bold" :startVal="startVal" :endVal="endVal" :duration="duration" />
              </Col>
            </Row>
            <Row :gutter="80" type="flex" justify="space-around" align="middle" style="">
              <Col>
                <div style="color: #f5f5f5; font-weight: bold; font-size: 16px">车辆数</div>
              </Col>
              <Col>
                <div style="color: #f5f5f5; font-weight: bold; font-size: 16px">加气站</div>
              </Col>
              <Col>
                <div style="color: #f5f5f5; font-weight: bold; font-size: 16px">检验机构</div>
              </Col>
            </Row> -->
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
const Echart = () => import('./components/page5/chart.vue')
const webBar = () => import('./components/page3/webBar.vue')
/*const webBars = () => import('./components/page5/webBar.vue');*/
const threeBarChart = () => import('./components/page5/linkPie')
// const LineMarker = () => import('./components/page5/LineMarker')
const pieChart = () => import('./components/page5/pieChart')
import CountTo from 'vue-count-to'
export default {
  name: 'page5',
  components: {
    Echart,
    webBar,
    /*  webBars,*/
    pieChart,
    threeBarChart,
    // LineMarker,
    CountTo
  },
  data() {
    return {
      dataBar3: {
        data: [213, 190, 137, 99, 63, 196, 248, 212, 248, 112]
      },
      workOrderRanking: {
        data: [
          {
            name: '张小明',
            value: 10
          },
          {
            name: '孙小莉',
            value: 5
          },
          {
            name: '顾小花',
            value: 6
          },
          {
            name: '李龙',
            value: 8
          },
          {
            name: '孙明',
            value: 8
          },
          {
            name: '冯晓',
            value: 2
          },
          {
            name: '沈丽',
            value: 5
          },
          {
            name: '苟胜',
            value: 3
          },
          {
            name: '李强',
            value: 8
          },
          {
            name: '陈飞',
            value: 7
          }
        ]
      },
      // dataBar2: {
      //   name: '电梯故障率排行',
      //   number: '100个',
      //   data: ['公司1', '公司2', '公司3'],
      //   color: '40,112,232',
      //   value: [60, 50, 40]
      // },
      dataBar: {
        // 柱图数据1
        name: '气瓶充装数量',
        data: ['男性', '女性', '总数'],
        color: '192,35,42',
        value: [15, 8, 23]
      },
      startVal: 0,
      clendVal: 20,
      jqendVal: 3,
      jyendVal: 23,
      czendVal: 3251,
      jysendVal: 0,
      qpendVal: 1836,
      endVal: 0,
      duration: 3000,
      buttonSize: 'mini',

      cdata1: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '河北省',
          value: 23,
          data: [
            { name: '石家庄市', value: 0 },
            { name: '唐山市', value: 0 },
            { name: '廊坊市', value: 0 },
            { name: '张家口市', value: 0 },
            { name: '邯郸市', value: 0 },
            { name: '保定市', value: 0 },
            { name: '承德市', value: 0 },
            {
              name: '秦皇岛市',
              value: 23,
              data: [
                { name: '海港区', value: 5, data: [{}] },
                { name: '山海关区', value: 3, data: [{}] },
                { name: '北戴河区', value: 3, data: [{}] },
                { name: '抚宁区', value: 3, data: [{}] },
                { name: '青龙满族自治县', value: 3, data: [{}] },
                { name: '卢龙县', value: 3, data: [{}] },
                { name: '昌黎县', value: 3, data: [{}] }
              ]
            },
            { name: '邢台市', value: 0 },
            { name: '沧州市', value: 0 },
            { name: '衡水市', value: 0 }
          ]
          // elseData: {
          //   // 这里放置地图 tooltip 里想显示的数据
          // },
        },
        {
          name: '山东省',
          value: 0
        },
        {
          name: '辽宁省',
          value: 0
        },
        {
          name: '广东省',
          value: 0
        },
        {
          name: '浙江省',
          value: 0
        },
        {
          name: '湖北省',
          value: 0
        },
        {
          name: '湖南省',
          value: 0
        },
        {
          name: '香港',
          value: 0
        },
        {
          name: '澳门',
          value: 0
        },
        {
          name: '北京市',
          value: 0
        },
        {
          name: '天津市',
          value: 0
        },
        {
          name: '安徽省',
          value: 0
        },
        {
          name: '黑龙江省',
          value: 0
        },
        {
          name: '吉林省',
          value: 0
        },
        {
          name: '内蒙古自治区',
          value: 0
        },
        {
          name: '山西省',
          value: 0
        },
        {
          name: '河南省',
          value: 0
        },
        {
          name: '四川省',
          value: 0
        },
        {
          name: '重庆市',
          value: 0
        },
        {
          name: '海南省',
          value: 0
        },
        {
          name: '贵州省',
          value: 0
        },
        {
          name: '云南省',
          value: 0
        },
        {
          name: '江西省',
          value: 0
        },
        {
          name: '福建省',
          value: 0
        },
        {
          name: '江苏省',
          value: 0
        },
        {
          name: '台湾省',
          value: 0
        },
        {
          name: '上海市',
          value: 0
        },
        {
          name: '陕西省',
          value: 0
        },
        {
          name: '宁夏回族自治区',
          value: 0
        },
        {
          name: '甘肃省',
          value: 0
        },
        {
          name: '青海省',
          value: 0
        },
        {
          name: '西藏自治区',
          value: 0
        },
        {
          name: '新疆维吾尔自治区',
          value: 0
        },
        {
          name: '广西壮族自治区',
          value: 0
        }
      ],
      boardConfig: {
        header: ['类型', '日期', '数量'],
        data: [
          /*          ['气瓶', moment().format('YYYY-MM-DD'), '400'],
          ['电梯', moment().format('YYYY-MM-DD'), '300'],
          ['气瓶', moment().format('YYYY-MM-DD'), '400'],
          ['电梯', moment().format('YYYY-MM-DD'), '360'],
          ['电梯', moment().format('YYYY-MM-DD'), '280'],
          ['电梯', moment().format('YYYY-MM-DD'), '180'],
          ['气瓶', moment().format('YYYY-MM-DD'), '90'],
          ['电梯', moment().format('YYYY-MM-DD'), '270'],
          ['气瓶', moment().format('YYYY-MM-DD'), '330'],
          ['气瓶', moment().format('YYYY-MM-DD'), '440'],*/
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        indexHeader: '序号',
        columnWidth: [80],
        index: true,
        align: ['center']
      },
      boardConfig2: {
        header: ['故障原因', '故障次数'],
        data: [
          /*   ['人为', '10'],
          ['自然', '8'],
          ['自然', '9'],
          ['自然', '7'],
          ['人为', '3'],
          ['自然', '6'],
          ['自然', '2'],
          ['人为', '5'],
          ['人为', '1'],*/
        ],
        headerBGC: 'transparent',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        indexHeader: '序号',
        columnWidth: [80, 120, 120],
        index: true,
        align: ['center']
      }
    }
  },
  methods: {
    mapData(val) {
      this.cdata1 = val
    },
    qgData(arr) {
      this.cdata1 = arr
    },
    sData(sda) {
      this.cdata1 = sda
    }
  }
}
</script>

<style lang="less" scoped>
.page5 {
  height: 100%;
  width: 100%;
  padding: 14px 20px 20px;
  overflow: hidden;
  .content {
    height: 100%;
    position: relative;
    .ivu-col {
      height: 100%;
    }
    .left,
    .right {
      height: 100%;
      border: 1px solid #0d2451;
      position: relative;

      #left_5 {
        height: 100%;
        width: 45%;
        float: left;
      }

      #left_6 {
        height: 100%;
        width: 55%;
        float: left;
      }

      .circular {
        height: 100%;

        .canvas {
          height: 100%;
          width: 30%;
          float: left;

          .subtitle {
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            height: 25px;
            padding: 10px 0;
            text-align: center;
          }

          .canvasList {
            height: calc(~'100% - 25px');
            text-align: center;
          }
        }
      }

      .left1 {
        border-top: 1px solid #0d2451;
        margin-top: 1rem;
      }

      .title {
        position: absolute;
        display: inline-block;
        color: #6eddf1;
        border: 2px solid #6eddf1;
        height: 18px;
        padding: 0 2px;
        left: 50%;
        transform: translate(-50%, -50%);

        .title-text {
          position: relative;
          font-size: 16px;
          background: #09102e;
          display: inline-block;
          padding: 0 4px;
          transform: translateY(-5px);
        }
      }
      .ivu-row {
        margin-top: 1rem;
        /*background: linear-gradient(to right,#4ceedb, #4444b7);*/
      }
      .ivu-radio-group {
        background: transparent;
        border-radius: 5px;
      }
      .ivu-radio-group-button .ivu-radio-wrapper {
        background: #0d2451;
        border: 1px solid #3de7c9;
        color: #f5f5f5;
      }
      .ivu-radio-group-button .ivu-radio-wrapper-checked {
        background: linear-gradient(to right, #4444b7, #4ceedb);
        color: #f5f5f5;
      }
    }
    .center-top {
      height: 60%;
      .border-box {
        .border-bottom-right {
          margin-top: 1rem;
          /* !*首先我们设置边框只显示底部，宽度为2px的实线。*!*/
          /* !*设置线性渐变*!*/
          background: url('../assets/images/border.png') no-repeat 0 100%;
          background-size: 100% auto;
        }
      }
      .border-box-right {
        .border-bottom-left {
          position: relative;
          margin-top: 1rem;
          overflow: hidden;
          /*首先我们设置边框只显示底部，宽度为2px的实线。*/
          /*设置线性渐变*/
          &::after {
            content: '';
            position: absolute;
            width: 120%;
            height: 90%;
            z-index: -1;
            background: url('../assets/images/border.png') no-repeat 0 100%;
            background-size: 100% auto;
            transform: rotateY(180deg);
          }
        }
      }
    }
    .center-bottom {
      height: 40%;
    }
  }
}
</style>
